import React, {useEffect} from 'react'
import './style.scss'
import { Link } from 'gatsby'


function BlogTemplate(props) {

    const hasMore  = props.posts.data?.posts.pageInfo.offsetPagination.hasMore
    const hasPrevious = props.posts.data?.posts.pageInfo.offsetPagination.hasPrevious
    const pagesCount = Math.ceil(props.posts.data?.posts.pageInfo.offsetPagination.total / 4)
   

    useEffect(()=>{
        window.scrollTo({
            top: 0,
            behavior: 'smooth',
          })
    }, [])


    let posts = props.posts.data?.posts.nodes.map( (node, i) => {
        return (
            <Link key={i} className="hover span-12 span-12-mobile" to={`/discover${node.uri}`}>
                <div key={i} className="single-item">
                    <div className="img-wrapper">
                        <img srcSet={node.featuredImage?.node.srcSet} alt=""/>
                    </div>
                   
                    <div className="content-container">
                        <h4> {node.title}</h4>
                        <button className='link'>Read More</button>
                    </div>
                </div>
            </Link>
        )
    })

    let pages = []

    for(let i = 0; i < pagesCount; i++) {
        pages.push(
            <li key={i} className={props.posts.currentOffset === 4*i ? 'current' : ''} onClick={ () => props.posts.setCurrentOffset(4*i)}>{i+1}</li>
        )
    }

   
  
	return (
        <div className="blog-template">
            <div className="content">
                <h2>Discover</h2>
                <div className={`wysiwyg-inject`} >
                    <p>
                    Welcome to a space where we share new 
                    discoveries related to solar. 
                    </p>
                </div>  
            </div>
        
            <div className="item-container flex-12">
                <div className="marker">
                    Discover
                </div>
                {
                props.posts.isLoading ?
                    <p className="loading">Loading..</p>
                :
                    posts
                }
                <div className="post-navigation">
                    <button disabled={hasPrevious ? false : true} className={`${hasPrevious ? "": "disable"} prev button`} onClick={ () => props.posts.setCurrentOffset(props.posts.currentOffset - 4)} >Prev</button>
                    <ul>
                        {pages}
                    </ul>
                    <button disabled={hasMore ? false : true} className={`${hasMore ? "": "disable"} next button`} onClick={ () => props.posts.setCurrentOffset(props.posts.currentOffset + 4)} >Next</button>
                </div>
            </div>
        </div>
	)
}

export default BlogTemplate