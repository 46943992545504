import React, {useContext} from 'react'
import { Router } from '@reach/router'
import ApolloContext from '../../context/ApolloContext'
import PostTemplate from './PostTemplate'
import BlogTemplate from './BlogTemplate'

function News(props) {
	const apollo = useContext(ApolloContext)

	const content = apollo.data?.posts.nodes.filter(function(key) {
		return key.slug === props.params['*']
	})[0]

	let Blog = () => {
		return (
			<BlogTemplate posts={apollo} />
		)
	}

	let SinglePost = () => {
		if(content) {
			return <PostTemplate post={content} />
		}
		return null
	}

	return (
		<div>
			<Router>
				<Blog path="/discover/" />
				<SinglePost path="/discover/:id" />
			</Router>
		</div>
	)
}

export default News 