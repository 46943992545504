import React from "react"
import Newz from '../apollo/news'
import { graphql } from "gatsby"

import Seo from "../components/seo"
import { Helmet } from "react-helmet"
import ReactHtmlParser from 'react-html-parser'

import Footer from '../components/Footer'



	function News(props) {
		// Just rerouting to apollo/news
		return (
			<main className={`c0 main-news lang-${props.data?.wpGeneralSettings?.generalSettings?.language}`}>

			{props.data?.wpPage?.yoast_head ?
			<Helmet>{ ReactHtmlParser(props.data?.wpPage?.yoast_head) }</Helmet>
			:
			<Seo title={props.data?.wpPage?.title ? props.data?.wpPage?.title : 'Discover'} description={props.data?.wpGeneralSettings?.generalSettings?.description} siteTitle={props.data?.wpGeneralSettings?.generalSettings?.title} />
			}	
					
			<Newz {...props} />
			<Footer/>
			</main>
		)
}

export default News  

export const query = graphql`
  query {
    ...wpNaviPrimary
    ...wpGeneralSettings
  }
`