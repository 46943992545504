import React from 'react'
import './style.scss'

function PostTemplate(props) {

	const post = props.post

	// let year = post.date.slice(0,4)
	// let month = post.date.slice(5,7)
	// let date = new Date(year, month, 0);
	// let monthName = date.toLocaleString('en-us', { month: 'long' });

	return (
		<div id="post-template">
  			<div className="img-container hero">
                <div className="img-wrapper"> 
                    <h2 className="title">{post?.title}</h2>
                    <img srcSet={post?.featuredImage?.node.srcSet} alt="" />
					{/* <h3 className="date gillsans">PUBLISHED {monthName} {year}</h3> */}
                </div>
        	</div>
			<div className="content">
				<div dangerouslySetInnerHTML={{__html: post?.content}}/>
			</div>
		</div>
	)
}

export default PostTemplate 